import { UncontrolledDropdown, Button, UncontrolledCollapse } from 'reactstrap';
import { useMemo, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDisclosure } from '../hooks/useDisclosure';
import images from '../images';
import styles from './header.module.scss';
import IconButton from './iconButton';
import Sidebar from './sidebar';
import { useCart } from '../context/cart';
import { useAuth } from '../context/auth';
import { auth } from '../auth';

const pages = [
  // {
  //   title: '#Elasusam',
  //   path: '/elasusam',
  //   private: false,
  //   mobile: true,
  //   desktop: true,
  // },
  {
    title: '#Elasusam',
    path: '/shop/elasusam',
    private: false,
    mobile: true,
    desktop: true,
    hasInfluencer: true,
    findText: null,
  },
  {
    title: 'Shop',
    path: '/shop/',
    category: '',
    findText: null,
    restart: true,
    private: false,
    mobile: true,
    desktop: true,
  },
  {
    title: 'Macacões',
    path: '/shop/jumpsuit',
    category: 'jumpsuit',
    private: false,
    mobile: false,
    desktop: true,
  },
  {
    title: 'Vestidos',
    path: '/shop/vestidos',
    category: 'dress',
    private: false,
    mobile: false,
    desktop: true,
  },
  {
    title: 'Look Inteiro',
    path: '/shop/jumpsuit',
    category: 'jumpsuit',
    private: false,
    mobile: true,
    desktop: false,
    type: 'submenu',
    child_routes: [
      {
        title: 'Macacões',
        path: '/shop/jumpsuit',
        category: 'jumpsuit',
        private: false,
        mobile: true,
        desktop: true,
      },
      {
        title: 'Vestidos',
        path: '/shop/vestidos',
        category: 'dress',
        private: false,
        mobile: true,
        desktop: true,
      },
    ],
  },

  {
    title: 'Blusas',
    path: '/shop/blusas',
    category: 'shirt',
    private: false,
    mobile: false,
    desktop: true,
  },
  {
    title: 'Top',
    path: '/shop/tops',
    category: 'top',
    private: false,
    mobile: false,
    desktop: true,
  },
  {
    title: 'Blazer',
    path: '/shop/blazers',
    category: 'blazer',
    private: false,
    mobile: false,
    desktop: true,
  },

  {
    title: 'Partes de Cima',
    path: '/shop/jumpsuit',
    category: 'jumpsuit',
    private: false,
    mobile: true,
    desktop: false,
    type: 'submenu',
    child_routes: [
      {
        title: 'Blusas',
        path: '/shop/blusas',
        category: 'shirt',
        private: false,
        mobile: true,
        desktop: true,
      },
      {
        title: 'Top',
        path: '/shop/tops',
        category: 'top',
        private: false,
        mobile: true,
        desktop: true,
      },
      {
        title: 'Blazer',
        path: '/shop/blazers',
        category: 'blazer',
        private: false,
        mobile: true,
        desktop: true,
      },
    ],
  },

  {
    title: 'Calças',
    path: '/shop/calcas',
    category: 'pants',
    private: false,
    mobile: false,
    desktop: true,
  },
  {
    title: 'Bermuda',
    path: '/shop/bermudas',
    category: 'bermuda',
    private: false,
    mobile: false,
    desktop: false,
  },

  {
    title: 'Partes de Baixo',
    path: '/shop/jumpsuit',
    category: 'jumpsuit',
    private: false,
    mobile: true,
    desktop: false,
    type: 'submenu',
    child_routes: [
      {
        title: 'Calças',
        path: '/shop/calcas',
        category: 'pants',
        private: false,
        mobile: true,
        desktop: true,
      },
      {
        title: 'Bermuda',
        path: '/shop/bermudas',
        category: 'bermuda',
        private: false,
        mobile: true,
        desktop: false,
      },
    ],
  },

  {
    title: 'Sale',
    path: '/shop/sale',
    sale: true,
    private: false,
    mobile: true,
    desktop: true,
  },
  {
    title: 'Compras',
    path: '/OrderHistory',
    private: true,
    mobile: true,
  },
  {
    title: 'Perfil',
    path: '/AccountProfile',
    private: true,
    mobile: true,
  },
  {
    title: 'Trocar Senha',
    path: '/ChangePassword',
    private: true,
    mobile: true,
  },
  {
    title: 'Sair',
    path: '/',
    private: true,
    mobile: true,
    action: true,
  },
];

export default function Header() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [collapsed, setCollapsed] = useState(false);

  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hiddenCompletely, setHiddenCompletely] = useState(false);
  const { cart } = useCart();
  const history = useHistory();
  const { user } = useAuth();
  const [findText, setFindText] = useState('');
  const dateTime = new Date().getMilliseconds();
  const location = useLocation();
  const [isShopPage, setIsShopPage] = useState(false);
  const isMobile = useMemo(() => {
    const media = window.matchMedia('(max-width: 768px)');

    return media.matches;
  }, []);

  function toggleBag() {
    const event = new CustomEvent('togglebag');
    document.dispatchEvent(event);
  }

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      onClose();
      setShowHeader(false); // esconde ao rolar pra baixo
    } else {
      setShowHeader(true); // mostra ao rolar pra cima
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    let timeout;
    if (!showHeader) {
      timeout = setTimeout(() => setHiddenCompletely(true), 400); // espera a animação acabar
    } else {
      setHiddenCompletely(false); // mostra imediatamente quando rola pra cima
    }
    return () => clearTimeout(timeout);
  }, [showHeader]);

  useEffect(() => {
    setIsShopPage(
      (location && location.pathname.trim() === '/') ||
        (location && location.pathname.trim().includes('shop'))
    );
  }, [location, setIsShopPage]);

  async function logout() {
    await auth.signOut();
    window.location.reload();
  }

  const navDesktop = useMemo(() => {
    return (
      <nav>
        {pages.map((page) =>
          page.desktop ? (
            <li key={page.path}>
              <Link
                to={{
                  pathname: page.path,
                  category: page.category,
                  findText: page.findText,
                  restart: page.restart,
                  sale: page.sale,
                  age: page.age,
                  elasusam: page.elasusam,
                }}
                onClick={onClose}
              >
                {page.title}
              </Link>
            </li>
          ) : (
            ''
          )
        )}
      </nav>
    );
  }, [onClose]);

  function navMobilePrivateSubMenu(page, index) {
    return (
      <>
        <UncontrolledDropdown
          nav
          inNavbar
          className="border-nav"
          onClick={() => setCollapsed(!collapsed)}
        >
          <Button
            className="dropdown-toggle buttonsubmenu"
            id={`toggler${index}`}
          >
            {page.title}
          </Button>

          <UncontrolledCollapse toggler={`#toggler${index}`} navbar>
            {page.child_routes &&
              page.child_routes.map((subNavLink) => (
                <li key={subNavLink.path}>
                  <Link
                    to={{
                      pathname: subNavLink.path,
                      category: subNavLink.category,
                      findText: subNavLink.findText,
                      restart: subNavLink.restart,
                      sale: subNavLink.sale,
                      age: subNavLink.age,
                    }}
                    onClick={onClose}
                  >
                    {subNavLink.title}
                  </Link>
                </li>
              ))}
          </UncontrolledCollapse>
        </UncontrolledDropdown>
      </>
    );
  }

  function navMobilePrivateMenu(page) {
    return (
      <li key={page.path} className="border-nav">
        <Link
          to={{
            pathname: page.path,
            category: page.category,
            findText: page.findText,
            restart: page.restart,
            sale: page.sale,
            age: page.age,
          }}
          onClick={page.action ? logout : onClose}
        >
          {page.title}
        </Link>
      </li>
    );
  }

  const navMobile = useMemo(() => {
    return <nav>{pages.map((page, index) => renderSubMenu(page, index))}</nav>;
  }, [onClose]);

  const navMobilePrivate = useMemo(() => {
    return (
      <nav>{pages.map((page, index) => renderSubMenuPrivate(page, index))}</nav>
    );
  }, [onClose]);

  function renderSubMenu(page, index) {
    const isPublicMobile = !page.private && page.mobile;
    const isSubMenu = page.type === 'submenu';
    if (isPublicMobile) {
      return isSubMenu
        ? navMobilePrivateSubMenu(page, index)
        : navMobilePrivateMenu(page);
    }
  }

  function renderSubMenuPrivate(page, index) {
    const isPrivateMobile = page.mobile;
    const isSubMenu = page.type === 'submenu';
    if (isPrivateMobile) {
      return isSubMenu
        ? navMobilePrivateSubMenu(page, index)
        : navMobilePrivateMenu(page);
    }
  }

  function renderMenu() {
    let sidebar;
    if (isMobile) {
      if (user) {
        sidebar = (
          <Sidebar isOpen={isOpen} onClose={onClose}>
            {navMobilePrivate}
          </Sidebar>
        );
      } else {
        sidebar = (
          <Sidebar isOpen={isOpen} onClose={onClose}>
            {navMobile}
          </Sidebar>
        );
      }
    } else {
      sidebar = navDesktop;
    }
    return sidebar;
  }

  function renderSearch() {
    return (
      <>
        <div className="d-flex justify-content-end">
          <IconButton onClick={() => setFindText('')} type="button">
            <img src={images.closeBlack} alt="close icon" />
          </IconButton>

          <input
            type="text"
            name={findText}
            className="input-search"
            placeholder="Qual look você está procurando?"
            value={findText}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                history.push({
                  pathname: '/shop',
                  findText,
                  dateTime,
                });
                onClose();
              }
            }}
            onChange={(e) => setFindText(e.target.value)}
          />

          <IconButton
            onClick={() => {
              history.push({
                pathname: '/shop',
                findText,
                dateTime,
              });
              onClose();
            }}
            className={styles.action}
            type="button"
            aria-label="Procurar"
          >
            <img src={images.search} alt="Procurar" />
          </IconButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={`header-fixed-promotion animated-header  ${
          showHeader ? 'show' : 'hide'
        } 
    ${hiddenCompletely ? 'd-none' : ''}`}
      >
        <div className="container">
          <div className="row">
            <div
              className={isMobile ? 'col-12 text-center' : 'col-12 text-center'}
            >
              Experimente presencialmente em BH na loja parceira:
              <a
                href="https://www.instagram.com/lulaine.oficial/"
                rel="noopener noreferrer"
                target="_blank"
              >
                LULAINE
              </a>
              <span className="mx-1">ou</span>
              <a
                href="https://www.instagram.com/anttinestore/"
                rel="noopener noreferrer"
                target="_blank"
              >
                ANTTINE
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.headerContainer} animated-header  ${
          showHeader ? 'show' : 'hide'
        } 
    ${hiddenCompletely ? 'd-none' : ''}`}
      >
        <header>
          <IconButton
            className={`${styles.action} ${styles.menuIcon}`}
            onClick={onToggle}
            type="button"
          >
            <img src={images.menu} alt="Abrir mebu" />
          </IconButton>

          <Link to="/" className={styles.logoContainer}>
            <img src={images.svelta} alt="Logo da Svelta" />
          </Link>
          {renderMenu()}
          <div className={styles.actions}>
            <IconButton
              // eslint-disable-next-line react/jsx-no-bind
              onClick={toggleBag}
              className={styles.action}
              type="button"
              aria-label="Sacola"
            >
              <img src={images.bag} alt="Sacola" />
              <span className={styles.counter}>{cart?.length}</span>
            </IconButton>
            <IconButton
              onClick={() => {
                history.push('/WishList');
              }}
              className={styles.action}
              type="button"
              aria-label="Favoritos"
            >
              <img src={images.heart} alt="Favoritos" />
            </IconButton>
            <IconButton
              onClick={() => {
                if (user) history.push('/AccountProfile/');
                else history.push('/Login/');
              }}
              className={styles.action}
              type="button"
              aria-label="Entrar/Sair"
            >
              <img src={user ? images.userCircle : images.userCircle} alt="" />
            </IconButton>
          </div>
        </header>
        {isShopPage ? <div className="col-12 mb-4">{renderSearch()}</div> : ''}
      </div>
    </>
  );
}
